var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('HeaderBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" 职称/职务 ")]},proxy:true},{key:"input",fn:function(){return [_c('a-input',{attrs:{"placeholder":"请输入关键字"},model:{value:(_vm.keyWord),callback:function ($$v) {_vm.keyWord=$$v},expression:"keyWord"}}),_c('a-select',{staticStyle:{"width":"120px"},attrs:{"placeholder":"请选择状态"},model:{value:(_vm.states),callback:function ($$v) {_vm.states=$$v},expression:"states"}},[_c('a-select-option',{attrs:{"value":null}},[_vm._v(" 全部 ")]),_c('a-select-option',{attrs:{"value":0}},[_vm._v(" 启用 ")]),_c('a-select-option',{attrs:{"value":1}},[_vm._v(" 停用 ")])],1),_c('a-upload',{attrs:{"showUploadList":false,"name":"file","customRequest":_vm.fileAction}},[_c('a-button',{attrs:{"type":"primary"}},[_vm._v("导入基础信息")])],1),_c('a',{staticClass:"left",attrs:{"href":_vm.$config.target + '/hxclass-management/job/excel',"target":"_blank","rel":"noopener noreferrer"}},[_c('a-button',{staticStyle:{"margin-right":"10px"}},[_vm._v("下载导入模板")])],1),_c('a-button',{staticClass:"btn",attrs:{"type":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.search()}}},[_vm._v("搜索")]),_c('a-button',{staticClass:"all_boder_btn",on:{"click":_vm.onAdd}},[_vm._v("新建")])]},proxy:true}])}),_c('div',{staticClass:"table-box"},[_c('a-table',{staticClass:"table-template",attrs:{"rowKey":function (item) { return item.jobId; },"columns":_vm.columns,"loading":_vm.loading,"data-source":_vm.tableData,"pagination":{
        total: _vm.total,
        current: _vm.pageNumber,
        defaultPageSize: _vm.pageSize,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return ("共" + total + "条");
        },
      }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, i){return [_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s((_vm.pageNumber - 1) * _vm.pageSize + i + 1)+" ")])]}},{key:"state",fn:function(item, row){return [_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(row.state == 0 ? "已启用" : "已停用")+" ")])]}},{key:"operation",fn:function(item){return [_c('div',{staticClass:"btn_router_link"},[_c('a',{on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("编辑")]),_c('span',[_vm._v("|")]),(item.state != 0)?_c('a',{on:{"click":function($event){return _vm.changeState(item.jobId, 0)}}},[_vm._v("启用")]):_c('a',{on:{"click":function($event){return _vm.changeState(item.jobId, 1)}}},[_vm._v("停用")]),(item.isFollow != 1)?_c('span',[_vm._v("|")]):_vm._e(),(item.isFollow != 1)?_c('a',{on:{"click":function($event){return _vm.deleteData(item)}}},[_vm._v("删除")]):_vm._e()])]}}])})],1),_c('a-modal',{attrs:{"okText":"确认","title":(_vm.state == 1 ? '新建' : '编辑') + '职称/职务',"width":"360px","confirm-loading":_vm.PreventLoad},on:{"ok":_vm.handleOk},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"modal_box"},[_c('span',{staticClass:"tag"},[_vm._v("职称/职务：")]),_c('a-input',{attrs:{"type":"text","placeholder":"请输入职称/职务"},model:{value:(_vm.label),callback:function ($$v) {_vm.label=$$v},expression:"label"}})],1)]),_c('LeadingIn',{attrs:{"columns":_vm.columnsss,"successList":_vm.successList,"failureList":_vm.failureList,"visible":_vm.importVisible,"handleCancel":_vm.modelhandleCancel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }